import { useState, useRef } from 'react';
import HTMLFlipBook from 'react-pageflip';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import '../Modal.css';

function Flipbook(props) {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const flipBookRef = useRef();

    const isMobile = window.innerWidth <= 500;

    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }
    function pagesList(){
      var pages = [];
      if (!isMobile) {
        pages.push(<div className="blank-page"></div>); // Insert a blank page at the beginning only for desktop
      }
      for(var i=1; i<=numPages; i++){
        pages.push(<div><Page scale={1} width={isMobile ? window.innerWidth : 550} pageNumber={i}/></div>);
      }
      if (!isMobile) {
        pages.push(<div className="blank-page"></div>); // Insert a blank page at the end only for desktop
      }
      return pages;
    }

    const nextPage = () => {
      flipBookRef.current.pageFlip().flipNext();
    }

    const prevPage = () => {
      flipBookRef.current.pageFlip().flipPrev();
    }

    return (
        <Document
        file="./CatalogoDistribuidores.pdf"
        onLoadSuccess={onDocumentLoadSuccess}
        className='modal-90w'
        >        
        <HTMLFlipBook width={isMobile ? window.innerWidth : 550} height={isMobile ? window.innerHeight : 777} ref={flipBookRef}>
            {pagesList()}
        </HTMLFlipBook>
        </Document>
    );
}
export default Flipbook;
